import React from 'react';

import { useAuth } from 'providers/auth';

import type { TokenRole } from 'providers/auth/types';

function getDisplayName(Component: React.ComponentType<any>) {
    return Component.displayName || Component.name || 'Component';
}

/**
 * HOC that wraps a component and only renders it if the user is authorized by role
 * to view it
 *
 * @param role - Role to check against
 * @param WrappedComponent - React component to wrap
 *
 * @returns
 *
 * @example
 * const MyComponent = () => <div>My component</div>;
 * const MyAuthorizedComponent = authorized('broker')(MyComponent);
 */
export const authorized =
    <P extends object>(role: TokenRole | TokenRole[]) =>
    (WrappedComponent: React.ComponentType<P>): React.ComponentType<P> => {
        const EnhancedComponent = (props: P): React.JSX.Element => {
            const { isAuthorized } = useAuth();

            if (!isAuthorized(role)) {
                return <div>You are not authorized to view this</div>;
            }

            // the typing for spreading props is... very complex. best way right now is to just type it as any
            // the problem is React with it's React.FC and its magic children 😒
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return <WrappedComponent {...(props as any)} />;
        };

        EnhancedComponent.displayName = `authorized(${getDisplayName(
            WrappedComponent
        )})`;

        return EnhancedComponent;
    };

export default authorized;
